import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Select from "react-select";

import CustomSelect from "components/reusable/custom_select";
import { DisplayEnvironment } from "contexts/displayEnvironment";
import { fetchPartnerSupportedCountries } from "util/api_util";
import { ISOCountryCodeToFullNameMap } from "util/format_helpers";

import { getJobTypes } from "util/selectors";

const isEmptyObject = (obj) => Object.keys(obj).length === 0;

const countryNameToCodeMapping = Object.entries(
  ISOCountryCodeToFullNameMap,
).reduce((acc, [code, name]) => {
  acc[name] = code;
  return acc;
}, {});

function FilterInput(props) {
  const filters = !isEmptyObject(props.filters)
    ? props.filters
    : {
        job_type: "All Job Types",
        result: "All Results",
      };

  const { partnerId } = useParams();
  const { environment } = React.useContext(DisplayEnvironment);

  const { isPending, data: supportedCountries } = useQuery({
    queryKey: ["fetchPartnerSupportedCountries", partnerId, environment],
    queryFn: async () => {
      const data = await fetchPartnerSupportedCountries(partnerId);
      if (data.error) {
        throw new Error(data.error);
      }

      const deduplicatedCountryCodes = [
        ...new Set(
          data?.countries?.map((c) => countryNameToCodeMapping[c] || c),
        ),
      ];

      const countryOptions = deduplicatedCountryCodes.map((countryCode) => ({
        label: !countryCode
          ? "None"
          : ISOCountryCodeToFullNameMap[countryCode] || countryCode,
        value: countryCode,
      }));
      return [{ value: null, label: "None" }, ...countryOptions];
    },
  });

  const selectedCountries = React.useMemo(
    () =>
      props.selectedCountries.map((countryCode) => ({
        value: countryCode,
        label: !countryCode
          ? "None"
          : ISOCountryCodeToFullNameMap[countryCode] || countryCode,
      })),
    [props.selectedCountries],
  );

  const jobTypeOptions = getJobTypes();
  jobTypeOptions.unshift({
    label: "All Job Types",
    value: "All Job Types",
  });

  const resultOptions = [
    { label: "All Results", value: "All Results" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
    { label: "Provisional Approval", value: "Provisional Approval" },
  ];

  const userListOptions = [
    { label: "All Users", value: "All Users" },
    { label: "Watchlisted", value: "watchlisted" },
    {
      label: "Suspected for Fraud",
      value: "suspected_for_fraud",
      isDisabled: !props.smileSecureEnabled,
    },
  ];

  const createDropdown = (options, filterType) => (
    <Select
      key={filterType}
      options={options}
      value={options.find(({ value }) => value === filters?.[filterType])}
      onChange={({ value }) => props.changeFilter(filterType, value)}
      defaultValue={options[0]}
    />
  );

  const pageFilters = {
    job_list: [
      { options: jobTypeOptions, filterType: "job_type" },
      { options: resultOptions, filterType: "result" },
    ],
    user_list: [{ options: userListOptions, filterType: "user_type" }],
  };

  const dropdowns = (pageFilters[props.pageName] || []).map(
    ({ options, filterType }) => createDropdown(options, filterType),
  );

  return (
    <div className="filters__select-container">
      {dropdowns}
      {props.pageName === "job_list" && (
        <CustomSelect
          btnStyles={{ height: 54, color: "#000000" }}
          placeholder="All Countries"
          pluralTex="Countries"
          onChange={props.onCountriesSelected}
          isSearchable
          isMulti
          hasEmpty
          value={selectedCountries}
          options={supportedCountries}
          isLoading={isPending}
        />
      )}
    </div>
  );
}

export default FilterInput;
