import React from "react";
import { Link, useParams } from "react-router-dom";

import { useQueryParams } from "hooks/useQueryParams";
import IconKYCRejected from "resources/img/icons/account-rejected.svg";
import IconKYCAccepted from "resources/img/icons/account-verified.svg";
import ClipboardIcon from "resources/img/icons/clipboard.svg";
import DotIcon from "resources/img/icons/dot.svg";
import errorWarning from "resources/img/icons/error-warning.svg";
import IconFile from "resources/img/icons/file.svg";
import IconInfo from "resources/img/icons/info--grey.svg";
import KYCCompleted from "resources/img/icons/kyc-completed.svg";
import KYCConfirmation from "resources/img/icons/kyc-confirmation.svg";
import KYCRejected from "resources/img/icons/kyc-rejected.svg";
import IconKYCSubmitted from "resources/img/icons/kyc_submitted.svg";
import IconTick from "resources/img/icons/tick--filled-green.svg";
import IconWarn from "resources/img/icons/triangle-warning.svg";
import IconKYCReview from "resources/img/icons/under-review.svg";
import RedXIcon from "resources/img/icons/x--red.svg";
import SaveImg from "resources/img/save.png";
import {
  createKYCDetail,
  fetchKYCDetail,
  fetchPartnerInfo,
  updateKYCDetail,
  updateKYCState,
} from "util/api_util";
import { getUserType } from "util/selectors";

const ValidationCriteria = {
  Nigeria: {
    incorporation_doc: "CAC Certificate",
    additional_doc: undefined,
  },
  Ghana: {
    incorporation_doc: "Certificate of Incorporation",
    additional_doc: "Form 3",
  },
  Kenya: {
    incorporation_doc: "KRA PIN Certificate or Certificate of Incorporation",
    additional_doc: "CR1",
  },
  Tanzania: {
    incorporation_doc: "BRELA",
    additional_doc: "Form 14a",
  },
  Uganda: {
    incorporation_doc: "Certificate of Incorporation",
    additional_doc: "Company Form 20",
  },
  "South Africa": {
    incorporation_doc: "CoR 14.1",
    additional_doc: undefined,
  },
  "United States": {
    incorporation_doc: "Delaware Certificate of Incorporation",
    additional_doc: undefined,
  },
  UK: {
    incorporation_doc: "Certificate of Incorporation",
    additional_doc: undefined,
  },
  Canada: {
    incorporation_doc: "Certificate of Incorporation",
    additional_doc: undefined,
  },
  Other: {
    incorporation_doc:
      "Incorporation document containing your company’s legal name and names of directors",
    additional_doc:
      "Document containing your company’s legal name and names of directors (if Incorporation document does not include Director’s information)",
  },
};

const FIELDS = [
  "country",
  "legal_name",
  "incorporation_doc",
  "additional_doc",
  "director_name",
  "director_title",
  "director_id_type",
  "director_id",
  "director_country",
  "director_email",
  "contact_name",
  "contact_title",
  "contact_email",
  "contact_phone",
];

function KYCForm() {
  const [isValid, setIsValid] = React.useState(false);
  const [state, setState] = React.useState({
    approvingKYC: false,
    rejectingKYC: false,
    rejectionComment: "",
    userType: getUserType(),
    id: undefined,
    country: undefined,
    selectedCountry: undefined,
    legal_name: "",
    incorporation_doc: undefined,
    additional_doc: undefined,
    director_name: "",
    director_title: "",
    director_id_type: "",
    director_id: undefined,
    director_country: "",
    director_email: "",
    contact_name: "",
    contact_title: "",
    contact_email: "",
    contact_phone: "",
    comment: "",
    partnerInfo: {},
    disabled: false,
    fileSizeErrors: {},
    modalOpen: false,
  });

  const { partnerId } = useParams();
  const { view: viewForm } = useQueryParams();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const kyc_detail = await fetchKYCDetail({ id: partnerId });
        if (kyc_detail && !kyc_detail.error) {
          let selectedCountry = "";
          if (kyc_detail.country) {
            selectedCountry = ValidationCriteria[kyc_detail.country]
              ? kyc_detail.country
              : "Other";
          }
          setState((prevState) => ({
            ...prevState,
            id: kyc_detail.id || undefined,
            country: kyc_detail.country || undefined,
            selectedCountry,
            legal_name: kyc_detail.legal_name || undefined,
            incorporation_doc: kyc_detail.incorporation_doc || undefined,
            additional_doc: kyc_detail.additional_doc || undefined,
            director_name: kyc_detail.director_name || undefined,
            director_title: kyc_detail.director_title || undefined,
            director_id_type: kyc_detail.director_id_type || undefined,
            director_id: kyc_detail.director_id || undefined,
            director_country: kyc_detail.director_country || undefined,
            director_email: kyc_detail.director_email || undefined,
            contact_name: kyc_detail.contact_name || undefined,
            contact_title: kyc_detail.contact_title || undefined,
            contact_email: kyc_detail.contact_email || undefined,
            contact_phone: kyc_detail.contact_phone || undefined,
            comment: kyc_detail.comment,
          }));
        }
      } catch (err) {
        console.error(err);
      }

      try {
        const resp = await fetchPartnerInfo({ partner: partnerId });
        setState((prevState) => ({
          ...prevState,
          partnerInfo: resp.partner || prevState.partnerInfo,
          kycState: resp?.partner?.company?.kyc_state || prevState.kycState,
        }));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [partnerId]);

  React.useEffect(
    () => setIsValid(validate()),
    [
      state.country,
      state.legal_name,
      state.incorporation_doc,
      state.additional_doc,
      state.director_name,
      state.director_title,
      state.director_id_type,
      state.director_id,
      state.director_country,
      state.director_email,
      state.contact_name,
      state.contact_title,
      state.contact_email,
      state.contact_phone,
    ],
  );

  const getProgress = () => {
    let counter = 0;
    FIELDS.forEach((key) => {
      if (state[key]) {
        counter += 1;
      }
    });
    return counter;
  };

  const buildForm = () => {
    const formData = new FormData();
    const fields = [
      "id",
      "country",
      "legal_name",
      "director_name",
      "director_title",
      "director_id_type",
      "director_country",
      "director_email",
      "contact_name",
      "contact_title",
      "contact_email",
      "contact_phone",
    ];

    fields.forEach((field) => {
      if (state[field]) {
        formData.set(field, state[field]);
      }
    });

    ["incorporation_doc", "additional_doc", "director_id"].forEach((file) => {
      if (state[file]) {
        if (typeof state[file].link === "string") {
          formData.set(file, state[file].name);
        } else {
          formData.set(file, state[file], state[file].name);
        }
      }
    });

    return formData;
  };

  const save = async (e, keepStateDisabled) => {
    setState({
      ...state,
      disabled: true,
    });

    const form = buildForm();

    if (!state.id) {
      try {
        const resp = await createKYCDetail(form, partnerId);

        if (resp.error) throw resp;

        setState({
          ...state,
          id: resp.id,
          disabled: keepStateDisabled || false,
          incorporation_doc: resp.incorporation_doc,
          additional_doc: resp.additional_doc,
          director_id: resp.director_id,
          modalOpen: !keepStateDisabled,
          kycState: resp.company.kyc_state,
        });

        return resp;
      } catch (err) {
        let errorMessage = err.error;
        if (errorMessage === "Not Authorized") {
          errorMessage = "Only account owners can submit KYC";
        }
        if (!keepStateDisabled) {
          setState({
            ...state,
            error: errorMessage,
            disabled: keepStateDisabled || false,
          });
        } else {
          throw err;
        }
      }
    } else {
      try {
        const resp = await updateKYCDetail(form);

        if (resp.error) throw resp;

        setState({
          ...state,
          disabled: keepStateDisabled || false,
          incorporation_doc: resp.incorporation_doc,
          additional_doc: resp.additional_doc,
          director_id: resp.director_id,
          modalOpen: !keepStateDisabled,
          kycState: resp.company.kyc_state,
        });

        return resp;
      } catch (err) {
        // fix the message. the error is re-thrown when re-submitting kyc
        if (err.error === "Not Authorized") {
          err.error = "Only account owners can submit KYC";
        }
        if (!keepStateDisabled) {
          setState({
            ...state,
            error: err.error,
            disabled: false,
          });
        } else {
          throw err;
        }
      }
    }
  };

  const approveKYC = () => {
    setState({
      ...state,
      modalOpen: true,
      approvingKYC: true,
    });
  };

  const rejectKYC = () => {
    setState({
      ...state,
      modalOpen: true,
      rejectingKYC: true,
    });
  };

  const validate = () => {
    const validationCriteria = ValidationCriteria[state.selectedCountry] || {};
    return FIELDS.every((field) => {
      if (field === "additional_doc" && !validationCriteria.additional_doc) {
        return true;
      }
      return Boolean(state[field]);
    });
  };

  const submit = async (e) => {
    try {
      const saved = await save(e, true);
      if (!saved) return;

      const resp = await updateKYCState({ kyc_state: "submitted" }, partnerId);
      if (resp.error) throw new Error(resp.error);

      setState({
        disabled: false,
        kycState: resp.success ? "submitted" : state.kycState,
      });
    } catch (err) {
      setState({
        ...state,
        error: err.error,
        disabled: true,
      });
    }
  };

  const submitKYCApproval = () => {
    setState({ ...state, disabled: true });

    updateKYCState(
      {
        kyc_state: "accepted",
        partner_id: partnerId,
      },
      partnerId,
    ).then((resp) => {
      try {
        if (resp.error) throw resp;
        const _state = { disabled: false };
        if (resp.success) {
          _state.kycState = "accepted";
        }
        setState({ ...state, ..._state });
      } catch (error) {
        setState({
          ...state,
          modalOpen: false,
          error: error.error,
          disabled: false,
        });
      }
    });
  };

  const submitKYCRejection = async () => {
    setState({ ...state, disabled: true, error: "" });

    try {
      const resp = await updateKYCState(
        {
          kyc_state: "rejected",
          partner_id: partnerId,
          comment: state.rejectionComment,
        },
        partnerId,
      );

      if (resp.error) throw resp;

      const _state = { disabled: false };
      if (resp.success) {
        _state.kycState = "rejected";
      }
      setState({ ...state, ..._state });
    } catch (error) {
      setState({
        ...state,
        modalOpen: false,
        error: error.error,
        disabled: false,
      });
    }
  };

  const removeFile = (property) => () => {
    setState({ ...state, [property]: undefined });
  };

  const handleFile = (property) => (e) => {
    const twoMB = 2 * 1024 * 1024;
    if (e.target.files[0].size > twoMB) {
      setState({
        ...state,
        fileSizeErrors: {
          ...state.fileSizeErrors,
          [e.target.name]: true,
        },
      });
      return;
    }

    setState({
      ...state,
      fileSizeErrors: {
        ...state.fileSizeErrors,
        [e.target.name]: false,
      },
    });

    setState({ ...state, [property]: e.target.files[0] });
  };

  const onInput = (property) => (e) => {
    if (property === "selectedCountry") {
      setState({
        ...state,
        [property]: e.target.value,
        country: e.target.value === "Other" ? undefined : e.target.value,
      });
      return;
    }
    setState({ ...state, [property]: e.target.value });
  };

  const toggleModal = () => {
    const { modalOpen } = state;
    setState({
      ...state,
      modalOpen: !modalOpen,
      approvingKYC: false,
      rejectingKYC: false,
      rejectionComment: "",
    });
  };

  const dismissError = () => {
    setState({
      ...state,
      error: undefined,
    });
  };

  const progress = getProgress();
  const companyName = state.partnerInfo?.company
    ? state.partnerInfo?.company?.name
    : "";
  let modal;
  let footer;
  let headerStatusIcon;
  let headerStatusText;
  let banner;
  const countryOptions = [<option value="" key="blank" />];
  Object.keys(ValidationCriteria).forEach((key) => {
    countryOptions.push(
      <option value={key} key={key}>
        {key}
      </option>,
    );
  });
  const hasFileSizeErrors =
    state.fileSizeErrors &&
    Object.values(state.fileSizeErrors).some((error) => error);
  if (
    !["submitted", "under_review", "accepted"].includes(state.kycState) &&
    !viewForm
  ) {
    footer = (
      <div className="kyc-form__footer">
        <button
          className="btn btn-white"
          onClick={save}
          disabled={state.disabled || hasFileSizeErrors}
        >
          Save
        </button>
        <button
          className="btn btn-primary"
          onClick={submit}
          disabled={state.disabled || !isValid}
        >
          Submit
        </button>
      </div>
    );
  }

  if (viewForm && state.kycState === "submitted") {
    footer = (
      <div className="kyc-form__footer">
        <button
          className="btn btn--red"
          onClick={rejectKYC}
          disabled={state.disabled || state.kycState === "rejected"}
        >
          Reject
        </button>
        <button
          className="btn btn-primary"
          onClick={approveKYC}
          disabled={state.disabled || state.kycState === "accepted"}
        >
          Approve
        </button>
      </div>
    );
  }

  if (
    ["submitted", "under_review", "accepted", "rejected"].includes(
      state.kycState,
    )
  ) {
    const stateText = {
      submitted: "Submitted",
      under_review: "Under Review",
      accepted: "Account Verified",
      rejected: "Account Not Verified",
    }[state.kycState];
    headerStatusText = <span>{stateText}</span>;
    const stateIcon = {
      submitted: IconKYCSubmitted,
      under_review: IconKYCReview,
      accepted: IconKYCAccepted,
      rejected: IconKYCRejected,
    }[state.kycState];
    headerStatusIcon = <img src={stateIcon} alt={state.kycState} />;
  }

  if (state.modalOpen) {
    if (viewForm) {
      if (state.approvingKYC) {
        modal = (
          <div className="kyc-form__modal">
            <div className="kyc-form__modal--body">
              <img src={KYCConfirmation} alt="confirm kyc approval" />
              <h2>Are you sure?</h2>
              <p>
                You are passing a final approval on partner {partnerId} after a
                thorough review of all submitted company documents and ID
                details.
              </p>
              <button className="btn btn-primary" onClick={submitKYCApproval}>
                Yes, I approve partner
              </button>
              <button className="btn btn-secondary" onClick={toggleModal}>
                View Review Page
              </button>
            </div>
          </div>
        );

        if (state.kycState === "accepted") {
          modal = (
            <div className="kyc-form__modal">
              <div className="kyc-form__modal--body">
                <img src={KYCCompleted} alt="partner kyc completed" />
                <h2>Partner KYC Completed</h2>
                <p>
                  Partner {partnerId} will receive an email notification about
                  the successful activation of their account.
                </p>
                <Link
                  to="/admin/kyc_reviews"
                  className="btn btn-primary link-btn"
                >
                  Back to Review Board
                </Link>
              </div>
            </div>
          );
        }
      }

      if (state.rejectingKYC) {
        modal = (
          <div className="kyc-form__modal">
            <div className="kyc-form__modal--body">
              <h2>
                Partner {partnerId} <br /> Account Rejected
              </h2>
              <label>
                <p>Reason for the rejection</p>
                <textarea
                  value={state.rejectionComment}
                  onChange={onInput("rejectionComment")}
                  name="rejectionComment"
                  rows="5"
                  placeholder="e.g. invalid incorporation document, blurry passport id"
                />
              </label>
              <p className="failure">{state.error}</p>
              <button className="btn btn--red" onClick={submitKYCRejection}>
                Confirm Rejection
              </button>
              <button className="btn btn-secondary" onClick={toggleModal}>
                View Review Page
              </button>
            </div>
          </div>
        );

        if (state.kycState === "rejected") {
          modal = (
            <div className="kyc-form__modal">
              <div className="kyc-form__modal--body">
                <img src={KYCRejected} alt="partner kyc rejected" />
                <h2>Partner KYC Rejected</h2>
                <p>
                  Partner {partnerId} will receive an email notification about
                  the failed attempt to verify their account.
                </p>
                <Link
                  to="/admin/kyc_reviews"
                  className="btn btn-primary link-btn"
                >
                  Back to Review Board
                </Link>
              </div>
            </div>
          );
        }
      }
    } else {
      modal = (
        <div className="kyc-form__modal">
          <div className="kyc-form__modal--body">
            <img src={SaveImg} alt="save and continue" />
            <h2>KYC Details Saved!</h2>
            <p>
              All details provided so far have been saved. You can continue from
              the same stage at any time.
            </p>
            <button className="btn btn-primary" onClick={toggleModal}>
              Back to KYC
            </button>
          </div>
        </div>
      );
    }
  }

  if (state.kycState === "accepted") {
    banner = (
      <div className="kyc-form__banner kyc-form__banner--accepted">
        <img src={IconTick} alt="account verified" />
        <p>
          Your account has been verified. You may now run jobs in production.
        </p>
      </div>
    );
  }
  if (state.kycState === "rejected") {
    banner = (
      <div className="kyc-form__banner kyc-form__banner--rejected">
        <img src={IconWarn} alt="account not verified" />
        <p>{state.comment} Please take a moment to make changes.</p>
      </div>
    );
  }

  let validationCriteria = ValidationCriteria[state.country];
  if (!validationCriteria) {
    validationCriteria = ValidationCriteria.Other;
  }
  const fileSizeLimitMessage = "size should be less 2 MB";

  return (
    <>
      {modal}
      {state.error && (
        <div className="no-info__container">
          <img
            className="icon--large pointer"
            src={errorWarning}
            alt="Error"
            onClick={dismissError}
          />
          <div className="no-info">{state.error}</div>
        </div>
      )}

      {!state.error &&
        (state.partnerInfo ? (
          <div className="kyc-form">
            <div className="kyc-form__header">
              <img
                className="kyc-form__header--icon"
                src={ClipboardIcon}
                alt="header-icon"
              />
              <div className="kyc-form__header--content">
                <div className="kyc-form__header--id">
                  <img
                    className="kyc-form__header--icon"
                    src={DotIcon}
                    alt="header-icon"
                  />
                  &nbsp;
                  <span className="kyc-form__header--label">Partner ID:</span>
                  &nbsp;
                  {partnerId}
                </div>
                <div className="kyc-form__header--name">{companyName}</div>
                <div className="kyc-form__header--progress">
                  <div className="kyc-form__header--progress-text">
                    <p>ID Verification Progress</p>
                    <p>{progress}/14</p>
                  </div>
                  <div className="kyc-form__header--progress-bar">
                    <div
                      style={{
                        height: ".25em",
                        width: `${(progress / 14) * 100}%`,
                        backgroundColor: "var(--products-lightblue)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="kyc-form__header--state-info">
                <p>
                  {headerStatusText}
                  {headerStatusIcon}
                </p>
                {viewForm && state.kycState === "accepted" && (
                  <p>{state.comment}</p>
                )}
              </div>
            </div>
            <div className="kyc-form__body">
              {banner}
              <div className="kyc-form__body-section">
                <div className="kyc-form__body-section-header">
                  <p>Company Details</p>
                  <hr />
                </div>
                <div className="kyc-form__body-input">
                  <label>Country of Incorporation</label>
                  {viewForm ? (
                    <p>{state.country}</p>
                  ) : (
                    <select
                      className="input"
                      value={state.selectedCountry || ""}
                      name="selectedCountry"
                      onChange={onInput("selectedCountry")}
                    >
                      {countryOptions}
                    </select>
                  )}
                  {!viewForm && state.selectedCountry === "Other" && (
                    <input
                      className="input"
                      type="text"
                      value={state.country}
                      onChange={onInput("country")}
                      name="country"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Company Legal Name</label>
                  {viewForm ? (
                    <p>{state.legal_name}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.legal_name}
                      onChange={onInput("legal_name")}
                      name="legal_name"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>
                    <div className="kyc-form__info-text">
                      Incorporation Document
                      <div className="kyc-tooltip-container">
                        <button type="button" className="kyc-tooltip icon-btn">
                          <img
                            src={IconInfo}
                            alt="more information"
                            className="kyc-form__info-icon"
                          />
                        </button>
                        <span role="tooltip">
                          {validationCriteria.incorporation_doc}
                        </span>
                      </div>
                    </div>
                    <div className="pdfLabel">
                      (.pdf, {fileSizeLimitMessage})
                    </div>
                  </label>

                  {state.incorporation_doc &&
                  typeof state.incorporation_doc.name === "string" ? (
                    <div className="kyc_form__uploaded-file">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.incorporation_doc.link}
                      >
                        <img src={IconFile} alt="file icon" />
                        <span>{state.incorporation_doc.name}</span>
                      </a>
                      {!viewForm && (
                        <button
                          className="icon-btn"
                          aria-label="remove incorporation doc"
                          onClick={removeFile("incorporation_doc")}
                        >
                          <img alt="remove" src={RedXIcon} />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div>
                      <input
                        accept=".pdf"
                        className="input"
                        type="file"
                        onChange={handleFile("incorporation_doc")}
                        name="incorporation_doc"
                      />
                      {state.fileSizeErrors.incorporation_doc && (
                        <div className="kyc_form__uploaded-error">
                          The file needs to be less than 2 MB.
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>
                    <div className="kyc-form__info-text">
                      Additional Document
                      <div className="kyc-tooltip-container">
                        <button type="button" className="kyc-tooltip icon-btn">
                          <img
                            src={IconInfo}
                            alt="more information"
                            className="kyc-form__info-icon"
                          />
                        </button>

                        <span role="tooltip">
                          {validationCriteria.additional_doc ||
                            `Not required in ${state.country}`}
                        </span>
                      </div>
                    </div>
                    <div className="pdfLabel">
                      (.pdf, {fileSizeLimitMessage})
                    </div>
                  </label>

                  {state.additional_doc &&
                  typeof state.additional_doc.name === "string" ? (
                    <div className="kyc_form__uploaded-file">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.additional_doc.link}
                      >
                        <img src={IconFile} alt="file icon" />
                        <span>{state.additional_doc.name}</span>
                      </a>
                      {!viewForm && (
                        <button
                          className="icon-btn"
                          aria-label="remove additional doc"
                          onClick={removeFile("additional_doc")}
                        >
                          <img alt="remove" src={RedXIcon} />
                        </button>
                      )}
                    </div>
                  ) : !viewForm ? (
                    <div>
                      <input
                        accept=".pdf"
                        className="input"
                        type="file"
                        onChange={handleFile("additional_doc")}
                        name="additional_doc"
                      />
                      {state.fileSizeErrors.additional_doc && (
                        <div className="kyc_form__uploaded-error">
                          The file needs to be less than 2 MB.
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="kyc-form__body-section">
                <div className="kyc-form__body-section-header">
                  <p>Director's Details</p>
                  <hr />
                </div>
                <div className="kyc-form__body-input">
                  <label>Full Name</label>
                  {viewForm ? (
                    <p>{state.director_name}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.director_name}
                      onChange={onInput("director_name")}
                      name="director_name"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Designation</label>
                  {viewForm ? (
                    <p>{state.director_title}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.director_title}
                      onChange={onInput("director_title")}
                      name="director_title"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>
                    <div>
                      Director's ID Card
                      <div className="pdfLabel">
                        (.png .jpg, {fileSizeLimitMessage})
                      </div>
                    </div>
                  </label>
                  {state.director_id &&
                  typeof state.director_id.name === "string" ? (
                    <div className="kyc_form__uploaded-file">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={state.director_id.link}
                      >
                        <img src={IconFile} alt="file icon" />
                        <span>{state.director_id.name}</span>
                      </a>
                      {!viewForm && (
                        <button
                          className="icon-btn"
                          aria-label="remove director id"
                          onClick={removeFile("director_id")}
                        >
                          <img alt="remove" src={RedXIcon} />
                        </button>
                      )}
                    </div>
                  ) : (
                    <div>
                      <input
                        accept="image/png, image/jpeg"
                        className="input"
                        type="file"
                        onChange={handleFile("director_id")}
                        name="director_id"
                      />
                      {state.fileSizeErrors.director_id && (
                        <div className="kyc_form__uploaded-error">
                          The file needs to be less than 2 MB.
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>ID Type</label>
                  {viewForm ? (
                    <p>{state.director_id_type}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.director_id_type}
                      onChange={onInput("director_id_type")}
                      name="director_id_type"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Country of Issuance</label>
                  {viewForm ? (
                    <p>{state.director_country}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.director_country}
                      onChange={onInput("director_country")}
                      name="director_country"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Email</label>
                  {viewForm ? (
                    <p>
                      <a
                        href={`mailto:${state.director_email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {state.director_email}
                      </a>
                    </p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.director_email}
                      onChange={onInput("director_email")}
                      name="director_email"
                    />
                  )}
                </div>
              </div>
              <div className="kyc-form__body-section">
                <div className="kyc-form__body-section-header">
                  <p>Contact Details</p>
                  <hr />
                </div>
                <div className="kyc-form__body-input">
                  <label>Full Name</label>
                  {viewForm ? (
                    <p>{state.contact_name}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.contact_name}
                      onChange={onInput("contact_name")}
                      name="contact_name"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Designation</label>
                  {viewForm ? (
                    <p>{state.contact_title}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.contact_title}
                      onChange={onInput("contact_title")}
                      name="contact_title"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Phone Number</label>
                  {viewForm ? (
                    <p>{state.contact_phone}</p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.contact_phone}
                      onChange={onInput("contact_phone")}
                      name="contact_phone"
                    />
                  )}
                </div>
                <div className="kyc-form__body-input">
                  <label>Email</label>
                  {viewForm ? (
                    <p>
                      <a
                        href={`mailto:${state.contact_email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {state.contact_email}
                      </a>
                    </p>
                  ) : (
                    <input
                      className="input"
                      type="text"
                      value={state.contact_email}
                      onChange={onInput("contact_email")}
                      name="contact_email"
                    />
                  )}
                </div>
              </div>
            </div>
            {footer}
          </div>
        ) : (
          <div className="loader__container">
            <div className="loader loader--centered" />
          </div>
        ))}
    </>
  );
}

export default KYCForm;
